/**
 * to-top.ts
 * Logic of to-top button on page end
 *
**/
var $ = require('jquery')
export class Header {
    /**
     * Constructor
    **/
    constructor () {
        /**
         * Initalise local constructor variables
        **/
        let self = this;

        /**
         * Call functions
        **/
        this.toggleSmallHeader();
        this.watchEvents();
    }

    /**
     * Function toggleSmallHeader
    **/
    protected toggleSmallHeader () {
        let target = window;
        let documentHtml = $('html');
        // get scroll position based for all browsers
        let scrollPos = target.pageYOffset || target.scrollY || $("body").scrollTop;

        if(scrollPos > 20) {
            if(!documentHtml.hasClass('js-l-header--small')) {
                documentHtml.addClass('js-l-header--small');
            }
        } else {
            if(documentHtml.hasClass('js-l-header--small')) {
                documentHtml.removeClass('js-l-header--small');
            }
        }
    }

    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/
    protected watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Toggle HTML class' - * if page is scrolled */
        $(window).on('scroll',function () {
            self.toggleSmallHeader();
        });
    }
}