/**
 * pagetree-sub.ts
 * PagetreeSub for flyout
**/
var $ = require('jquery');
export class PagetreeSub {

    /**
     * Constructor
    **/
    constructor () {
        this.watchEvents();
    }

    /**
     * Function toggleFlyoutNav():void
     * hide secondary navigation and remove classes
    **/
    protected toggleFlyoutNav():void {

        /**
         * Initalise function variables
        **/
        let self = this;

        /* Watch click event on opener */
        $('.js-l-flyout-nav__opener').on('click touchend', function (e: any) {
            $('.l-pagetree-sub__secondary').hide();
            $('.js-l-pagetree-sub__opener').removeClass('l-pagetree-sub__item--open');

            /* Show active submenu */
            self.activeNav();
        });
    }

    /**
     * Function toggleNav():void
     * Open clicked submenu
    **/
    protected toggleNav():void {
        $('.js-l-pagetree-sub__opener').on('click touchend', function (e: any) {
            let subNavID = $(this).data('subnav-primary');

            $('.js-l-pagetree-sub__opener').removeClass('l-pagetree-sub__item--open');
            $(this).addClass('l-pagetree-sub__item--open');
            $('.l-pagetree-sub__secondary').hide();
            $('*[data-subnav-secondary="' + subNavID + '"]').show();

            return false;
        });
    }

    /**
     * Function activeNav():void
     * Open actual subnavigation in flyout
    **/
    protected activeNav():void {
        $('.js-l-pagetree-sub__opener').each(function (e: any) {
            if($(this).hasClass('l-pagetree-sub__item--active')) {
                let subNavID = $(this).data('subnav-primary');
                $('*[data-subnav-secondary="' + subNavID + '"]').show();
            }
        });
    }

    /**
     * Function watchEvents():void
     * Watches all events of the sub-navigation
    **/
    protected watchEvents():void {

        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Call functions
        **/
        self.activeNav();
        self.toggleFlyoutNav()
        self.toggleNav();
    }
}