/**
 * to-top.ts
 * Logic of to-top button on page end
**/
var $ = require('jquery')
export class toTop {
    /**
     * Define class variables
    **/
    public animationDuration:number;

    /**
     * Constructor
    **/
    constructor (animationDuration: number=900) {

        /**
         * Initalise local constructor variables
        **/
        let self = this;

        /**
         * Set parameters
        **/
        self.animationDuration = animationDuration;

        /**
         * Call functions
        **/
        this.checkVisibility();
        this.watchEvents();
    }

    /**
     * Function initAction():void
     * set/reset to-top classes
    **/

    protected checkVisibility():void {
        if ($(window).scrollTop() > 9) {
            $('.js-l-to-top').addClass('l-to-top--active');
        } else {
            $('.js-l-to-top').removeClass('l-to-top--active');
        }
    }

    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/
    protected watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Toggle bodyclass 'js-l-to-top--visible'
         * if page is scrolled
        **/
        $(window).on('scroll',function () {
            self.checkVisibility();
        });

        /**
         * Animate HTML to top
        **/
        $('.js-l-to-top').on('click',function(event: any) {
            $('body,html').stop().animate({
                scrollTop: 0}, self.animationDuration);
            event.preventDefault();
        });
    }
}