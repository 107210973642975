/**
 * locations.ts
**/


/* Declare google globally */
export declare var google: any;

require("expose-loader?Locations!./locations");

declare var bounds: any;

class Locations {

    /**
     * Define class variables
    **/
    public markers:any = [];

    /**
     * Constructor
    **/
    constructor () {
    }

    /**
     * Function setMarkers():void
     * Set markers of map instance
    **/
    public setMarkers(map:any, locations:any) {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Do for every location */
        for (var i = 0; i < locations.length; i++) {
            var location = locations[i];
            var infoContent = location[5];
            var image = {
                url: location[4]
            };

            var locationInfowindow = new google.maps.InfoWindow({
                content: infoContent,
                maxWidth: 300
            });

            var marker = new google.maps.Marker({
                position: {lat: location[1], lng: location[2]},
                map: map,
                icon: image,
                zIndex: location[3],
                title: location[0],
                infowindow: locationInfowindow
            });

            self.markers.push(marker);

            google.maps.event.addListener(marker, 'click', function() {
                self.hideAllInfoWindows(map);
                this.infowindow.open(map, this);
            });

            var loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(loc);
        }

        map.fitBounds(bounds);
        map.panToBounds(bounds);
        map.setOptions({maxZoom:16});
        map.setOptions({minZoom:2});
    }

    /**
     * Function hideAllInfoWindows():void
     * Hides information window of map instance
    **/
    public hideAllInfoWindows(map:any) {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Close every info window */
        self.markers.forEach(function(marker:any) {
            marker.infowindow.close(map, marker);
        });
    }

    /**
     * Function initMap():void
     * Initalize google map instance
    **/
    public initMap(mapid:any, locations:any) {

        /**
         * Initalise function variables
        **/
        let self = this;

        /* Create a map instance with several options */
        var map = new google.maps.Map(document.getElementById(mapid), {
            zoom: 15,
            center: {lat: 0.0, lng: 0.0 },
            streetViewControl: false,
            styles: [
                // modify style: https://mapstyle.withgoogle.com/
                {
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                    ]
                },
                {
                    "elementType": "labels.icon",
                    "stylers": [
                    {
                        "visibility": "off"
                    }
                    ]
                },
                {
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#616161"
                    }
                    ]
                },
                {
                    "elementType": "labels.text.stroke",
                    "stylers": [
                    {
                        "color": "#f5f5f5"
                    }
                    ]
                },
                {
                    "featureType": "administrative.land_parcel",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#bdbdbd"
                    }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "labels",
                    "stylers": [
                    {
                        "visibility": "off"
                    }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#ffffff"
                    }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#757575"
                    }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#dadada"
                    }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#616161"
                    }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels",
                    "stylers": [
                    {
                        "visibility": "off"
                    }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                    ]
                },
                {
                    "featureType": "transit.line",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#e5e5e5"
                    }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#eeeeee"
                    }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry",
                    "stylers": [
                    {
                        "color": "#c9c9c9"
                    }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels.text.fill",
                    "stylers": [
                    {
                        "color": "#9e9e9e"
                    }
                    ]
                }
            ]
        });

        bounds = new google.maps.LatLngBounds();
        self.setMarkers(map, locations);
    }
}

module.exports = Locations;