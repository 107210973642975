/**
 * flyout.ts
 * Logic of flyout element
**/
var $ = require('jquery')
export class Flyout {
    /**
     * Define class variables
    **/
    protected flyoutClass = 'l-flyout';
    protected flyoutClassJS = 'js-l-flyout';
    protected flyoutOpenAdditive = 'open';
    protected flyoutElement = $('.' + this.flyoutClassJS);
    protected flyoutOpenClass = this.flyoutClass + '--' + this.flyoutOpenAdditive;

    /**
     * Constructor
    **/
    constructor () {
        /**
         * Initalise function variables
        **/
        let self = this;
    }

    /**
     * Function openFlyout():void
     *
    **/
    public openFlyout():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Add open class to flyout */
        self.flyoutElement.addClass(self.flyoutOpenClass);
    }

    /**
     * Function closeFlyout():void
     *
    **/
    public closeFlyout():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Remove open class to flyout */
        self.flyoutElement.removeClass(self.flyoutOpenClass);
    }
}