/**
 * pageloader.ts
 * Logic of pageloader (spinner)
 *
**/
var $ = require('jquery')
export class pageLoader {
   /**
    * Define class variables
    **/
   public maximumTimeVisible:number;

    /**
    * Constructor
    **/
    constructor (maximumTimeVisible: number=3000) {
        /**
        * Initalise local constructor variables
        **/
        let self = this;

        /**
         * Set parameters
        **/
        self.maximumTimeVisible = maximumTimeVisible;

        /**
         * Call functions
        **/
        /* Hide loader after page is fully loaded */
        $(window).on('load', function(){
            self.hideLoader();
        });

        /* Hide loader after maximumtime if page is loading too long */
        setTimeout(function() {
            self.hideLoader();
        }, self.maximumTimeVisible);
    }

    /**
     * Function hideLoader():void
     * hide spinner after page is fully loaded
    **/
    protected hideLoader():void {
        $('.js-l-pageloader').fadeOut(100);
    }
}