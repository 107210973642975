/**
 * filter.ts
 * Filter, that automatically generates buttons and associated filter options
**/
var $ = require('jquery');

export class Filter {

    /**
     * Initalise class variables
    **/
    public filter:JQuery;
    private activeFilter: JQuery;
    private validFilterResult: boolean;
    private lastFilterRemoved: any;

    /**
     * Constructor
     * call 'new Filter()'
    **/
    constructor () {
        /**
         * Initalise local constructor variables
        **/
        let self = this;
        self.filter = $('.js-e-filter');
        self.activeFilter = self.filter.find('.e-filter__selection');
        self.validFilterResult = false;

        /**
         * Call functions
        **/
        self.initializeFilter();
        self.watchEvents();
    }

    /**
     * Function initializeFilter():void
     * Initalize filter
     * gets filterable values and generates a dynamic html for filter buttons
    **/
    public initializeFilter():void {

        /**
         * Initalise function variables
        **/
        let self = this;
        let storedFilter:any = null;

        /* Initalize webstorage */
        if (typeof(Storage) !== "undefined") {
            // ?
        } else {
            // Sorry! No Web Storage support..
        }

        var generatedHTML = '';

        /**
         * Get filterable values
        **/
        let filterInstance = self.filter;
        let tableHeader = filterInstance.next().find('table').find('thead').find('th');
        let tableRows = filterInstance.next().find('table').find('tbody').find('tr');
        var filterColumns:object = {};
        var filterColumnsIndexArray:any[] = [];
        var filterValues:any[] = [];
        var filterValuesText:object = {};
        var filterValuesIds:object = {};
        let activeFilter = filterInstance.find('.e_filter__active-filter');

        tableHeader.each((index, element) => {
            let tableHeaderInstance = $(element);
            let name: string = tableHeaderInstance.data('filterName');
            let displayName: string = tableHeaderInstance.text();
            let filterIsActive = false;

            /* For all bricks / compontents without active filter selection */
            if(activeFilter.length == 0)
                filterIsActive = true;

            if(name) {

                activeFilter.each((index, element) => {
                    if(name == $(element).data('active-filter'))
                        filterIsActive = true;
                });

                if(filterIsActive == true) {
                    filterValues[index] = [];
                    filterValuesText[index] = [];
                    filterValuesIds[index] = [];
                    filterColumns[index] = {};
                    filterColumns[index]['index'] = index;
                    filterColumns[index]['name'] = name;
                    filterColumns[index]['displayName'] = displayName;
                    filterColumnsIndexArray.push(index);
                }
            }
        });

        tableRows.each((index, element) => {
            let tableRowsInstance = $(element);
            let tableCells = tableRowsInstance.find('td');

            tableCells.each((index: any, element: any) => {
                if(jQuery.inArray(index,filterColumnsIndexArray) !== -1){

                    let elementText = $(element).data('filterValues');

                    if (elementText) {
                        /* Get ids of all filterable values */
                        let elementIds = $(element).data('filterIds').toString();
                        let elmentIdsArray = elementIds.split(',');

                        $.each(elmentIdsArray, function(i: any, element: any){
                            elmentIdsArray[i] = element.trim();
                        });

                        /* Get text of all filterable values */
                        let elmentTextArray = elementText.split(',');

                        $.each(elmentTextArray, function(index: any, element: any){
                            elmentTextArray[index] = element.trim();
                        });

                        /**
                         * Set ids and texts to global array
                         *
                         * Format: {TableHead-Nr : {ID: TEXT}, {ID: Text},...}
                         * Example: {2: {37: Eckental}, {251: Augsburg},...}
                         *
                        **/
                        $.each(elmentIdsArray, function(i: any, element: any){
                            filterValues[index][element] = elmentTextArray[i];
                        });
                    }
                }
            });
        });

        /* Sort filters */
        $.each(filterValues, function(index:any, element:any){
            if(element && element != undefined) {
                /* Array in format [ [ key1, val1 ], [ key2, val2 ], ... ] */
                filterValues[index] = self.sortProperties(element);
            }
        });

        /* Apply sorted array to text and id array */
        $.each(filterValues, function(index: any, element: any){
            $.each(element, function(key: any,value: any){
                if(value && value != undefined) {
                    filterValuesIds[index].push(value[0]);        // Key
                    filterValuesText[index].push(value[1]);        // Value
                }
            });
        });

        /**
         * Build HTML
        **/
        var acceptText = filterInstance.find('.filter-placehoder').text();
        for (let key in filterColumns) {
            if (filterValuesText[key].length > 1) {
                generatedHTML += '<div class="e-filter__buttons" data-filter-column="' + filterColumns[key]['name'] + '">';
                generatedHTML += '<button type="button" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">' + filterColumns[key]['displayName'] + '</button>';
                generatedHTML += '<div class="dropdown-menu e-form"><div class="dropdown-menu-scrollable">';

                for(let entry in filterValuesText) {
                    if(entry === key) {
                            $.each(filterValuesText[entry], function (index: any, element: any) {
                            generatedHTML += '<div class="e-form__checkbox-wrapper">';
                            generatedHTML += '<input type="checkbox" name="check" data-filter-id="' + filterValuesIds[entry][index] + '" value="' + filterValuesText[entry][index] +'" id="check-' + filterColumns[key]['name'] + '-' + filterValuesIds[entry][index] +'">';
                            generatedHTML += '<label for="check-' + filterColumns[key]['name'] + '-' + filterValuesIds[entry][index] +'">' + filterValuesText[entry][index] + '</label>';
                            generatedHTML += '<span></span></div>';
                        });
                    }
                }
                generatedHTML += '</div><button class="filter-apply">' + acceptText + '</button></div></div>    ';
            }
        }
        filterInstance.find('.filter-placehoder').replaceWith(generatedHTML);

        /* Check if a filter for this page was already set */
        storedFilter = this.getLocalStore();

        if (storedFilter != null) {
            let minResult:any = false;

            this.filter.find('.e-filter__buttons').each(function(i, v) {
                var f: JQuery = $(v);
                var column: string = f.data('filter-column');
                var checkboxes: JQuery = f.find('input[type=checkbox]');

                /* Skip non existing filter options */
                if (!storedFilter[column] || $.isEmptyObject(storedFilter[column]) ) {
                    return false;
                }

                checkboxes.each(function(i: number, c: HTMLInputElement) {
                    var value: string = $(c).val() as string;
                    var id: string = $(c).data('filterId') as string;
                    if (storedFilter[column][id] == id ) {
                        c.checked = true;
                        minResult = true;
                    }
                });
            });

            if(minResult == true) {
                /* Apply the filter once */
                this.applyFilters();
            }
        }
    }



    /**
     * Function watchEvents():void
     * Watches all events of the filter
    **/
    public watchEvents():void {

        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Hide whole filter if no filters are available
        **/
        if(self.filter.find('.e-filter__buttons').length == 0){
            self.filter.hide();
        }

        /**
         * Watch for clicks on filter dropdown menus
         * stops propagation
        **/
        self.filter.find('.dropdown-menu').on('click', function(e: any) {
            e.stopPropagation();
        });

        /**
         * Watch for clicks on filter apply buttons
         * search for checked "checkboxes"
         * calls "applyFilters"
        **/
        self.filter.find('button.filter-apply').on('click', function() {
            var p: JQuery = $(this).parents('.e-filter__buttons');
            var selected: JQuery = p.find('input[type=checkbox]').filter(function(i: number, c: HTMLInputElement) { return c.checked; })
            p.data('selected', selected);
            self.applyFilters();
        });

        /**
         * Restore checkbox state on close (to last applied)
        **/
        self.filter.find('.e-filter__buttons').on('show.bs.dropdown', function () {
            var selected: JQuery = $(this).find('input[type=checkbox]').filter(function(i: number, c: HTMLInputElement) { return c.checked; })
            $(this).data('selected', selected);
        });

        /**
         * Resets checkbox states on close (to last applied)
        **/
        self.filter.find('.e-filter__buttons').on('hide.bs.dropdown', function () {
            var checkboxes: JQuery = $(this).find('input[type=checkbox]');
            var selected: JQuery = $(this).data('selected') as JQuery;

            checkboxes.each(function(i: number, checkbox: HTMLInputElement) {
                checkbox.checked = $(checkbox).is(selected);
            });
        });

        /**
         * Watch for click on reset filter button
         * resets all filter to unchecked state
         * calls "applyFilters"
        **/
        self.activeFilter.find('button.filter-clear-all').on("click", function() {
            var checkboxes: JQuery = self.filter.find('input[type=checkbox]').each(function(i: number, c: HTMLInputElement) {
                c.checked = false;
            });
            self.filter.find('.js-e-filter__textsearch--input').val('');
            self.applyFilters();
        });

        /**
         * Watch for click on delete active filter
         * Saves id for "lastFilterRemoved"
        **/
        self.filter.find('.e-filter__selection-error button').on('click', function() {
            if ($(this).hasClass('filter-clear-all')) {
                self.activeFilter.find('button.filter-clear-all').trigger('click');
            } else {
                self.resetLastFilter();
            }
        });

        /**
         * Watch for text search element
        **/

        /* Init a timeout variable to be used below */
        var timeout:any = null;

        self.filter.find('.js-e-filter__textsearch--input').keyup(function(){
            var searchWord:any = $(this).val();
            /* Call function only if length is 0 or if length is => 3 */
            if (searchWord.length < 3 && searchWord.length != 0) {
                return false;
            }

            clearTimeout(timeout);
            /* Apply filter after 500 ms */
            timeout = setTimeout(function () {
                self.lastFilterRemoved = null;
                self.applyFilters();
            }, 500);
        });

        self.filter.find('.js-e-filter__textsearch--wrapper-clear').on('click', function () {
            self.filter.find('.js-e-filter__textsearch--input').val('');
            self.applyFilters();
        });
    }

    /**
     * Function addActiveFilter(text: string, checkbox: HTMLInputElement)
     * Adds an active filter button below filter dropdowns
    **/
    public addActiveFilter(text: string, id: string, checkbox: HTMLInputElement) {
        var that: Filter = this;
        var existing: JQuery = this.activeFilter.find('button').filter(function() {
            return $(this).text() == text && $(this).data('filterId') == id;
        });

        if (existing.length === 0) {
            var button: JQuery = $('<button data-filter-id="' + id + '">').text(text);
            button.data('checkbox', checkbox);

            button.on("click", function() {
                var c: HTMLInputElement = $(this).data("checkbox");
                c.checked = false;
                /* Save last  */
                that.lastFilterRemoved = $(this).data('filter-id');
                that.applyFilters();
            });

            this.activeFilter.append(button);
            var textNode: Text = document.createTextNode(' ');
            this.activeFilter.append(textNode);
        }
        this.activeFilter.removeClass("d-none");
    }

    /**
     * Function removeActiveFilter(text: string)
     * Removes an active filter button below filter dropdowns
    **/
    public removeActiveFilter(text: string, id: string) {
        this.activeFilter.find('button').filter(function() {
            return $(this).text() == text && $(this).data('filterId') == id;
        }).remove();
        if (this.activeFilter.find('button').length == 1) {
            this.activeFilter.addClass("d-none");
        }
    }

    /**
     * Function checkValidFilterResult()
     * Checks if the result is of filtering is valid (>= one row as result)
    **/
    public checkValidFilterResult() {
        /**
         * Initalise function variables
        **/
        let self = this;
        /* Filter result not valid - rerun filtering and delete all filters before*/
        if (self.validFilterResult == false) {
            self.filter.find('.e-filter__selection-error').removeClass("d-none");
            if (!self.lastFilterRemoved) {
                self.filter.find('.e-filter__selection-error .filter-clear-last').addClass('d-none');
            } else {
                self.filter.find('.e-filter__selection-error .filter-clear-last').removeClass('d-none');
            }
        } else {
            self.filter.find('.e-filter__selection-error').addClass('d-none');
        }
    }

    /**
     * Function resetLastFilter()
     * Readds the last removed filter
    **/
    public resetLastFilter() {
        let self = this;
        this.filter.find('.e-filter__buttons').each(function(i, v) {
            var f: JQuery = $(v);
            var checkboxes: JQuery = f.find('input[type=checkbox]');

            checkboxes.each(function(i: number, c: HTMLInputElement) {
                var id: string = $(c).data('filterId') as string;
                if (self.lastFilterRemoved == id ) {
                    c.checked = true;
                }
            });
        });

        this.applyFilters();
    }

    /**
     * Function setLocalStore(name: string, data: any)
     * set a local storage
    **/
    public setLocalStore(data:any) {
        let filterName = $('html').data('page-id') + "-filter";
        let filterData = data;

        filterData = JSON.stringify(data);
        localStorage.setItem(filterName, filterData);
    }

    /**
     * Function getLocalStore(name: string, data: any)
     * set a local storage
    **/
    public getLocalStore():object {
        let filterName = $('html').data('page-id') + "-filter";

        if (localStorage.getItem(filterName) === null) {
            return null;
        } else {
            let storedFilter = localStorage.getItem(filterName);
            return JSON.parse(storedFilter);
        }
    }

    /**
     * Function applyFilters(text: string)
     * Applies the filter to the corresponding table values
    **/
    public applyFilters() {
        let table = this.filter.next().find('table');
        var that: Filter = this;
        var filters: object = {};
        var availableValues = {};
        that.validFilterResult = false;

        this.filter.find('.e-filter__buttons').each(function(i, v) {
            var f: JQuery = $(v);
            var column: string = f.data('filter-column');
            var checkboxes: JQuery = f.find('input[type=checkbox]');

            checkboxes.each(function(i: number, c: HTMLInputElement) {
                var value: string = $(c).val() as string;
                var id: string = $(c).data('filterId') as string;
                if (!filters[column]) { filters[column] = {}; }
                if (c.checked) {
                    filters[column][id] = id;
                    that.addActiveFilter(value, id, c);
                } else {
                    delete filters[column][id];
                    that.removeActiveFilter(value, id);
                }
            });
        });

        /* Set value of textsearch */
        var textValue:any = this.filter.find('.js-e-filter__textsearch--input').val();
        var columnMap: object = {};
        table.find('th').each(function(i, th) {
            var name: string = $(th).data('filterName');
            if (name) {
                columnMap[i] = name;
            }
        });

        table.find('tbody tr').each(function(i, tr) {
            var show: boolean = true;
            var textSearchFound:boolean = false;

            $(tr).find('td').each(function(i: any, td: any) {
                if(textSearchFound == false  && textValue != undefined) {
                    if($(td).find('a').text().toLowerCase().indexOf(textValue.toLowerCase()) >= 0 && textValue != '') {
                        textSearchFound = true;
                    } else {
                        textSearchFound = false;
                    }
                }

                /* Filter not suitable, do not check other values */
                if(show == false){
                    return;
                }

                var column: string = columnMap[i];

                if (column) {

                    /* Textsearch not valid */
                    if (textSearchFound == false && textValue != '' && textValue != undefined) {
                        show = false;
                    }

                    var elementIds = $(td).data('filterIds');
                    if (elementIds) {
                        elementIds = elementIds.toString();
                        var elmentIdsArray = elementIds.split(',');
                        let done = false;
                        $.each(elmentIdsArray, function(index: any, element: any){
                            if(done == true){
                                return;
                            }
                            elmentIdsArray[index] = parseInt(element.trim());
                            /* If filter column does not exists (maybe too few entries ) */
                            if (typeof filters[column] === 'undefined')
                                return;

                            /* If elementID check is OK - stop the loop and go to next row */
                            if ( filters[column][elmentIdsArray[index]] && !$.isEmptyObject(filters[column])){
                                show = true;
                                done = true;
                            }
                            /* If elementID check is NOT OK - set visible to false and go to next ID of same element */
                            else {
                                if (!filters[column][elmentIdsArray[index]] && !$.isEmptyObject(filters[column])){
                                    show = false;
                                }
                            }
                        });
                    } else {
                        show = false;
                        /* TD is not filterable */
                        if ($.isEmptyObject(filters[column])) {
                            show = true;
                        }
                    }
                }
            });
            if (show) {
                $(tr).find('td').each(function(i: any, td: any) {
                    var column: string = columnMap[i];

                    if (column) {
                        var elementIds = $(td).data('filterIds');
                        if (elementIds) {
                            elementIds = elementIds.toString();
                            var elmentIdsArray = elementIds.split(',');
                            if (!availableValues[column]) availableValues[column] = [];

                            $.each(elmentIdsArray, function(index: any, element: any){
                                availableValues[column].push(parseInt(element.trim()));
                                availableValues[column] = that.unique(availableValues[column]);
                            });
                        }
                    }
                });
            }
            $(tr).toggle(show);
        });

        /* Disable checkboxes which would result in an empty table */
        this.filter.find('.e-filter__buttons').each(function(i, v) {
            var f: JQuery = $(v);
            var column: string = f.data('filter-column');
            var checkboxes: JQuery = f.find('input[type=checkbox]');
            checkboxes.each(function(i: number, c: HTMLInputElement) {
                var value: string = $(c).val() as string;
                var id: string = $(c).data('filterId') as string;
                if (!availableValues[column] || $.isEmptyObject(filters[column]) ) {
                    c.disabled = true;
                    if($.inArray(id, availableValues[column]) >= 0) {
                        c.disabled = false;
                        that.validFilterResult = true;
                    }
                } else {
                    c.disabled = false;
                    that.validFilterResult = true;
                }
            });
        });

        that.filter.find('.e-filter__buttons.show button.dropdown-toggle').dropdown('toggle');
        that.checkValidFilterResult();
        that.setLocalStore(filters);
    }

    /**
     * Function unique(text: array)
     * removes duplicate entries from a given array
    **/
    public unique(list:any[]) {
        var result:any = [];
        $.each(list, function(i:any , e:any) {
          if ($.inArray(e, result) == -1) result.push(e);
        });
        return result;
    }

    /**
     * Function sortProperties(obj: array)
     * sorts object by keys
    **/
    public sortProperties(obj:any)
    {
        /* Convert object into array */
        var sortable:any = [];

        for (var key in obj)
            if(obj.hasOwnProperty(key))
                /* Each item is an array in format [key, value] */
                sortable.push([key, obj[key]]);

        /* Sort items by value */
        sortable.sort(function(a:any, b:any)
        {
            var x=a[1].toLowerCase(),
                y=b[1].toLowerCase();
            return x<y ? -1 : x>y ? 1 : 0;
        });

        /* Array in format [ [ key1, val1 ], [ key2, val2 ], ... ] */
        return sortable;
    }
}