var $ = require('jquery');
require('devbridge-autocomplete');
$(function() {
    var $el = $('input.search-field'),
    language = $el.data('language'), //optional
    // country = $el.data('country'),
	$categoryEl = $el.closest('form').children('input.searchCategory'),
    categories = []; //optional

	$categoryEl.each(function(e){
		categories.push($(this).val());
	});

    $el.autocomplete({
        minChars: 3,
        triggerSelectOnValidInput: false,
        lookup: function(term, done) {
            $.getJSON(
                '/lucence-search/auto-complete',
                {
                    q: term,
                    language : language,
                    // country: country,
                    categories: categories
                },
                function(data) {
                    var result = { suggestions : [] };
                    if(data.length > 0) {
                        $.each(data, function(index, suggession) {
                            result.suggestions.push( {value : suggession });
                        });
                    }
                    done(result);
                });
        },
        onSelect: function(result) {
            $el.val(result.value);
            $el.parents('form').submit();
        }
    });
});