/**
 * autocomplete-zip.ts
 *
**/
var google  = require("../../5_xtensions/locations/locations")

class testZip {
    /**
     * Function initAutocompleteZip():void
     * Initialize autocomplete
    **/
    public initAutocompleteZip(id: any, isocodea2:any) {

        var input = document.getElementById(id);

        /* Chrome bug */
        var observerHack = new MutationObserver(function() {
            observerHack.disconnect();
            $("#contactsBundleZip").attr("autocomplete", "new-password");
        });

        observerHack.observe(input, {
            attributes: true,
            attributeFilter: ['autocomplete']
        });

        var options = {
            componentRestrictions: {'country':isocodea2},
            /* cities */
            types: ['(regions)']
        };

        var autocomplete = new google.maps.places.Autocomplete(<HTMLInputElement> input, options);

        /* Add Event listener - for 'place changed' */
        google.maps.event.addListener(autocomplete, 'place_changed', function() {
            var location = autocomplete.getPlace();
            var geocoder = new google.maps.Geocoder();
            var lat = location['geometry']['location'].lat();
            var lng = location['geometry']['location'].lng();
            var latlng = new google.maps.LatLng(lat,lng);

            geocoder.geocode(
                {
                    bounds: new google.maps.LatLngBounds(latlng)
                },
                function(results: any) {
                    var postal_code = '';
                    var addressComponents = results[0].address_components;

                    for (var i = 0; i < addressComponents.length; i++) {
                        if (addressComponents[i].types[0] == "postal_code") postal_code = results[0].address_components[i].long_name;
                    }
                    $('#'+id).val(postal_code);
                }
            );
        });
    }
}
module.exports = testZip;