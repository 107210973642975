/**
 * sidebar.ts
**/
var $ = require('jquery')
export class Sidebar {
    /**
     * Constructor
    **/
    constructor () {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Call functions
        **/
        self.watchEvents();
    }

    /*
    * Function openSidebar
    * opens sidebar on click
    */
    protected openSidebar():void {
        $('.l-sidebar__icon').on('click', function() {
            $('.l-sidebar__item').not($(this).parent()).removeClass('open');
            $(this).parent('.l-sidebar__item').toggleClass('open');
        });
    }

    /*
    * Function hideSidebar
    * hides sidebar on click
    */
    protected hideSidebar():void {
        let windowHeight = $(window).height();

        if(windowHeight <= 500) {
            $(".l-sidebar").hide();
        } else {
            $(".l-sidebar").show();
        }
    }

    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/
    protected watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Call functions
        **/
        self.openSidebar();
        self.hideSidebar();

        /* Hide sidebar on resize */

        $(window).on('resize',function () {
            self.hideSidebar();
        });
    }
}