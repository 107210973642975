/**
 * panel.ts
 * Logic of panel element
**/


var $ = require('jquery')
export class Panel {

    /**
     * Initalise class variables
    **/
    protected scrollbarWidth:number;
    protected panelClass:string = 'l-panel';
    protected panelClassJS:string = 'js-l-panel';
    protected panelOpenAdditive:string = 'open';
    protected panelBackdropClass:string = '__backdrop';
    protected panelElement:JQuery = $('.' + this.panelClassJS);
    protected panelOpenClass:string = this.panelClass + '--' + this.panelOpenAdditive;
    protected panelBackdropElement:JQuery = $('.' + this.panelClassJS + this.panelBackdropClass);
    protected headerWidth:number = 1140;

    /**
     * Constructor
    **/
    constructor () {
        /**
         * Initalise local constructor variables
        **/
        let self = this;

        /**
         * Call functions
        **/
        self.measureScrollbar();
        self.watchEvents();
    }

    /*
    * Function measureScrollbar():void
    * Measures scrollbar width of browser and adds a div
    * that prevents jumping of content
    */
    public measureScrollbar():void {

        /**
         * Initalise function variables
        **/
        let self = this;
        let scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        self.scrollbarWidth = window.innerWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
    }

    /*
    * Function openPanel():void
    *
    */
    public openPanel():void {

        /**
         * Initalise function variables
        **/
       let self = this;

        /* Add open class to panel */
        self.panelElement.addClass(self.panelOpenClass);

        /* Add open class from body and header wrapper and set dummy margin (used for imaginary scrollbar) */
        $('.js-l-body').addClass(self.panelOpenClass);
        $('.js-l-body').addClass(self.panelOpenClass).css({marginRight: self.scrollbarWidth + 'px'});
        // if($(window).width() < self.headerWidth )
        $('.l-header__wrapper').css({paddingRight: self.scrollbarWidth + 'px'});
    }

    /*
    * Function closePanel():void
    */
   public closePanel():void {

        /**
         * Initalise function variables
        **/
        let self = this;

        /* Remove open class from panel */
        self.panelElement.removeClass(self.panelOpenClass);

        /* Remove open class from body and header wrapper and set dummy margin to zero (used for imaginary scrollbar) */
        $('.js-l-body').removeClass(self.panelOpenClass);
        $('.js-l-body').removeClass(self.panelOpenClass).css({marginRight: '0'});
        $('.l-header__wrapper').css({paddingRight: '0'});
    }

    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/
    public watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Toggle open-class for several elments 'js-l-panel--open'
        **/
        self.panelBackdropElement.on('click touchend', function (e) {
            e.preventDefault();
            self.closePanel();
        });

        /**
         * Watch resizing of window to reset padding of header
        **/
        $(window).on('resize', function() {
            if($(window).width() < self.headerWidth && $('.js-l-body').hasClass(self.panelOpenClass))
                $('.l-header__wrapper').css({paddingRight: self.scrollbarWidth + 'px'});
            else
                $('.l-header__wrapper').css({paddingRight: '0'});
        });
    }
}