/**
 * content-cols.ts
**/
var $ = require('jquery')
export class ContentCols {
    /**
     * Constructor
    **/
    constructor () {
        /**
         * Initalise function variables
        **/

        let self = this;

        /**
         * Call functions
        **/
        self.watchEvents();
    }

    /**
     * Function initContentCols():void
     * initialises content col elements (brick)
    **/
    protected initContentCols():void {
        $('.c-content-cols').not('.editmode .c-content-cols').each(function() {
            var $list = $(this),
                $items = $list.find('.c-content-cols__headline');

            $items.css('height', 'auto');

            var maxHeight = 0;
            $items.each( function()
            {
                var itemHeight = Number($( this ).outerHeight());
                if (itemHeight > maxHeight) {
                    maxHeight = itemHeight;
                }
                if (itemHeight == 1) {
                    $(this).addClass('no-height');
                }
            });
            $items.each(function(){
                if(!$(this).hasClass('no-height')) {
                    $(this).css('height', maxHeight);
                }
            });
        });
    }

    /**
     * Function watchEvents():void
     * Watches all events of the base class
    **/
    protected watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;
        self.initContentCols();
    }
}