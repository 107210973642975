/**
 * contacts.ts
**/
var AutocompleteZip = require('../../2_elements/autocomplete-zip/autocomplete-zip')
require ("expose-loader?Contacts!./contacts");
class Contacts {
    /**
     * Define class variables
    **/

    /**
     * Constructor
    **/
    constructor () {
    }

    /**
     * Function initContacts():void
     * Initialize contacts
    **/
    public initContacts(locationsArray:any, path:any) {

        /**
         * Initalise function variables
        **/
        let self = this;

        /* Add event handler */
        $('#contactsBundleCountrySelect').on("change", function() {
            let selectedCountry = $(this).val();
            self.showZip(selectedCountry, locationsArray);
        })

        $( "#js-x-contact__search-button" ).on( "click", function() {
            self.showContact(path);
            return false;
        });

        self.showContact(path);

        var parameterCountry = this.GetURLParameter('country');
        self.showZip(parameterCountry, locationsArray);
    }

    /**
     * Function showContact():void
     *
    **/
    public showContact(path:any) {
        var str = $( "form" ).serialize();
        var url = path + str
        $.ajax({
            url: url,
            success: function( data ) {
                $( "#js-x-contact__result" ).html(data);
            }
        });
    }

    /**
     * Function showZip():void
     *
    **/
    public showZip(country:any, locationsArray:any) {
        if (jQuery.inArray(country, locationsArray) != -1) {
            $('#js-x-contact__search-zip').show();
            // var autocompleteZip = new AutocompleteZip();
            // autocompleteZip.initAutocompleteZip('contactsBundleZip', country);
        } else {
            $('#js-x-contact__search-zip').hide();
        }
    }
    public GetURLParameter(sParam:any) {
        var sPageURL = window.location.search.substring(1);
        var sURLVariables = sPageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var sParameterName = sURLVariables[i].split('=');
            if (sParameterName[0] == sParam) {
                return sParameterName[1];
            }
        }
    }
}
module.exports = Contacts;