/**
 * hero.ts
 * Logic of hero component
**/

var $ = require('jquery')
export class Hero {
    /**
     * Initalise class variables
    **/
    public animationDuration:number;
    public scrollFactor:number;

    /**
     * Constructor
    **/
    constructor (animationDuration: number=1000, scrollFactor: number=0.7) {

        /**
         * Initalise local constructor variables
        **/
        let self = this;

        /**
         * Set parameters
        **/
        self.animationDuration = animationDuration;
        self.scrollFactor = scrollFactor;

        /**
         * Call functions
        **/
        self.watchEvents();
    }

    /**
     * Function watchEvents():void
     * Watches all events of the hero component and handles scrolling
    **/
    public watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Watch scroll event for text-box */
        $(window).scroll((e: any) => {
            this.parallax();
        });

        /* Watch click event on CTO button */
        $('.js-hero__scroll').on('click', (e: any) => {
            let headerHeight = $('.js-l-hero__header').height();
            $('html, body').stop().animate({
                scrollTop: $('.pimcore_area_content').first().offset().top - headerHeight }, this.animationDuration);
            event.preventDefault();
        }); 

        /* Video Volume */
        // $(".js-l-hero__video-volume").click( function (){
        //     if( $(".l-hero video").prop('muted') ) {
        //             $(".l-hero video").prop('muted', false);
        //     } else {
        //         $(".l-hero video").prop('muted', true);
        //     }
        //     $(this).toggleClass('off');
        // });
    }

    /**
     * Function parallax():void
     * Set margin-top to text-box (small parallax effect)
    **/
    public parallax():void {
        /**
         * Initalise function variables
        **/
        let self = this;
        let scrolled = $(window).scrollTop();

        /* Set margin of text-box */
        $('.l-hero__design--full .l-hero__text').css('margin-top',(scrolled*this.scrollFactor)+'px');
    }
}