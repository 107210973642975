/**
 * base.ts
 *
**/

import 'bootstrap'
import 'tablesorter'

/* Declare google globally */
var picturefill = require('picturefill')
var $ = require('jquery')
var Stickyfill = require('stickyfilljs')
var ClipboardJS = require('clipboard')

export class Base {
    /**
     * Constructor
    **/
    constructor () {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Call functions
        **/

        /* initTableSorter - JS-Lib for sortable tables */
        self.initTableSorter();

        /* initStickyElements - Stickyfill for several browsers */
        self.initStickyElements();

        /* picturefill */
        picturefill();

        /* Apply clipboard function */
        self.initClipboardElements();

        /* Watch events */
        self.watchEvents();
    }

    /**
     * Function backLink():void
     * creates / get a back link from document referrer or history
    **/
    protected backLink():void {
        $('.js-base__back-link').on('click', function() {
            let backURL = $(this).data('back-url');
            if (document.referrer.indexOf(window.location.host) !== -1) { history.go(-1); return false; } else { window.location.href = backURL; }
        });
    }

    /**
     * Function anchorAnimation():void
     * initialises / calls the tablesorter (external lib)
    **/

    protected anchorAnimation():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Listen on anchors with hashes
        **/
        $('.e-rte a[href^="#"]').on('click', function (event:any) {
            /* Prevent default behavior of anchors */
            event.preventDefault();

            /* Disabled if anchor has class backlink */
            if ($(this).hasClass('js-base__back-link')) {
                return false;
            }

            /* Get target of anchor elements */
            var target:any = $(this).attr('href');
            target = $(target);

            /* Scroll to target */
            $('html, body').stop().animate({
                scrollTop: target.offset().top - 200 }
            , 300);
        });

        if(window.location.hash) {
            var target:any = window.location.hash;
            target = $(target);

            $('html, body').stop().animate({
                scrollTop: target.offset().top - 200 }
            , 300);
        }
    }

    /**
     * Function initBootstrapPopover():void
     * initialises / calls bootstrap popover
    **/
    protected initBootstrapPopover():void {
        /* Call popover on click */
        $('[data-toggle="popover"]').popover();

        /* Hide popover on click */
        $('.e-button__clipboard').click(function() {
            $('[data-toggle="popover"]').popover('hide');
            $(this).addClass('e-button__clipboard--copy');
            setTimeout(function() {
                $('.e-button__clipboard').removeClass('e-button__clipboard--copy');
            }, 2000);
        });
    }

    /**
     * Function initTableSorter():void
     * initialises / calls the tablesorter (external lib)
    **/
    protected initTableSorter():void {
        /* Set responsive class for each table */
        $('.e-rte table').each(function() {
            var tableContent = $(this).html();
            var tableID = $(this).attr('id');
            var tableClass = $(this).attr('class');
            $(this).replaceWith(function () {
                return $('<div class="table-responsive"><table id="' + tableID + '" class="' + tableClass + '">' + tableContent + '</table></div>');
            });
        });

        /* Call tablesorter function */
        $(".js-main__tablesorter").each(function() {
            let self:any = $(this);
            var rowCount = $('tr', this).length;
            if(rowCount >= 5) {
                self.tablesorter();
            }
        });
    }

    /**
     * Function initStickyElements():void
     * initialises stickyfill (external lib)
    **/
    protected initStickyElements():void {
        /* Apply the polyfill for sticky elements */
        var elements = $('.sticky');
        Stickyfill.add(elements);
    }

    /**
     * Function initClipboardElements():void
     * initialises Clipboard (external lib)
    **/
    protected initClipboardElements():void {
        /* Apply clipboard function to elements */
        let clipboard = new ClipboardJS('.e-button__clipboard');
    }



    /**
     * Function formErrorScrollHandler():void
     * scrolls to error
    **/
    protected formErrorScrollHandler():void {
        var error:any;
        $('*[type=submit]').on('click', function(){
            error = 0;
            $("input, textarea, select").focus(function() {
                if (error === 0) {
                    error = 1

                    $('html, body').animate({
                        scrollTop: $(this).offset().top - 200
                    },'slow');
                }
            });
        })
    }

    /**
     * Function watchEvents():void
     * Watches all events of the base class
    **/

    protected watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Call functions
        **/

        /* Backlink */
        self.backLink();

        /* AnchorAnimation */
        self.anchorAnimation();

        /* Bootstrap popover */
        self.initBootstrapPopover();

        /* From error scroll handler */
        self.formErrorScrollHandler();
    }
}