/**
 * panel-nav.ts
 * Logic of pagetree navigation in panel
**/
import { Panel } from '../page/panel/panel';
import { PagetreePanel } from '../nav/pagetree/pagetree-panel';
var $ = require('jquery');
export class Panelnav {
    /**
     * Initalise class variables
    **/
    protected panel:Panel = null;

    /**
     * Constructor
    **/
    constructor (panel:Panel, accordionMode:boolean=true,openCurrent:boolean=true,animationDuration: number=1000,overviewpagesPossible :boolean=false) {
        /**
         * Initalise local constructor variables
        **/
        let self = this;
        self.panel = panel;

        /**
         * Call functions
        **/
        self.watchEvents();

        /**
         * Get instance of panelmenu
        **/
        let pagetreePanel = new PagetreePanel(accordionMode, openCurrent, animationDuration, overviewpagesPossible);
    }

    /**
     * Function watchEvents():void
     * Watches all events of the navigation class
    **/
    public watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Toggle navigation */
        $('.js-l-pagetree-panel__opener').on('click touchend', function (e: any) {
            e.preventDefault();
            self.panel.openPanel();
        });

        $('.js-panel-nav__close').on('click touchend', function (e: any) {
            e.preventDefault();
            self.panel.closePanel();
        });
    }
}