/**
 * flyout-nav.ts
 * Logic of pagetree navigation in flyout
**/

import { Flyout } from '../page/flyout/flyout';
import { PagetreeSub } from '../nav/pagetree/pagetree-sub';
var $ = require('jquery')

export class FlyoutNav {
    /**
     * Define class variables
    **/
    protected flyout:Flyout = null;

    /**
     * Constructor
    **/
    constructor (flyout: Flyout) {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Set class variables
        **/
        self.flyout = flyout;


        /**
         * Call functions
        **/
        self.watchEvents();

        /* Get instance of PagetreeSecondary */
        let pagetreeSub = new PagetreeSub();
    }

    /**
     * Function toggleNav():void
     *
    **/
    protected toggleNav():void {

        /**
         * Initalise function variables
        **/
        let self = this;

        $('.js-l-flyout-nav__opener').on('click touchend', function (e: any) {
            let mainNavID = $(this).data('mainnav-main');

            /* Event if flyout is already open*/
            if($('.js-l-flyout').hasClass('l-flyout--open')) {
                /* If opened menu item is clicked again */
                if($('.l-pagetree-sub[data-mainnav-sub="' + mainNavID + '"]').is(':visible')) {
                    self.closeNav();
                    return false;
                }
            }

            /* Remove active class from flyout opener */
            $('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open'); 

            /* Open flyout */
            self.flyout.openFlyout();

            /* Set current menu point active */
            $(this).addClass('l-pagetree-main__item--open');

            /* Hide all sub navigations of flyout navigation */
            $('.l-pagetree-sub').hide();

            /* Show navigation with selected ID */
            $('.l-pagetree-sub[data-mainnav-sub="' + mainNavID + '"]').show();

            return false;
        });

        /* Close menu on page content click */
        $('.l-page__content, .l-footer').on('click touchend', function (e: any) {
            self.closeNav();
        });
    }

    /**
     * Function closeNav():void
     *
    **/
    protected closeNav():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Remove active class from flyout opener */
        $('.js-l-flyout-nav__opener').removeClass('l-pagetree-main__item--open');

        /* Close flyout */
        self.flyout.closeFlyout();
    }

    /**
     * Function watchEvents():void
     * Watches all events of the FlyoutNav class
    **/
    protected watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Call functions
        **/
        self.toggleNav();

        /* Close menu on resize & scroll */
        $(window).scroll(() => {
            self.closeNav();
        });

        $(window).on('resize',function () {
            self.closeNav();
        });
    }
}