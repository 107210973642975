/**
 * cookie-message.ts
 *
**/
var $ = require('jquery')
export class CookieMessage {
    /**
     * Constructor
    **/
    constructor () {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Call functions
        **/
        self.watchEvents();
    }

    /*
    * Function showMessage
    */
    protected showMessage():void {
        if(document.cookie.indexOf('hideCookieMessage=1') != -1){
            $('.l-cookie-message').addClass('close');
        }
        else{
            $('.l-cookie-message').removeClass('close');
        }
    }

    /*
    * Function closeMessage
    */
    protected closeMessage():void {
        $('.l-cookie-message__button-ok').on('click', function() {
            $(this).parents('.l-cookie-message').addClass('close');
            // Save cookie for one year
            var date = new Date();
            var expireTime = new Date(date.getTime() + 31536000000);
            document.cookie = 'hideCookieMessage=1;' + 'expires=' + expireTime.toUTCString() + ';' + 'domain='+window.location.hostname+';path=/';

            return false;
        });
    }

    /**
     * Function watchEvents():void
     * Watches all events of the FlyoutNav class
    **/
    protected watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Call functions
        **/
        self.showMessage();

        /* Close cookie message */
        self.closeMessage();
    }
}