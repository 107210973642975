/**
 * navigation.ts
 * Logic of navigation
**/
var $ = require('jquery')
export class Landingpagenavigation {
    /**
     * Initalise class variables
    **/
    public animationDuration:number;
    /**
     * Constructor
    **/

    constructor (animationDuration: number=1000) {
        /**
         * Initalise local constructor variables
        **/
        let self = this;

        /**
         * Set parameters
        **/
        self.animationDuration = animationDuration;

        /**
         * Call functions
        **/
        self.initializeMenu();
        self.watchEvents();
    }

   /**
     * Function initializeMenu():void
     * Initalize menu
     * Create HTML elements for landingpagenavigation
    **/
   public initializeMenu():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Build navigation-frame for header- and burgernav
        **/
        $('.js-landingpage__topic-nav').html('<ul class="l-topic-nav"></ul>');
        $('.js-landingpage__panel-nav ').html('<ul class="l-pagetree-panel"></ul>');

        /**
         * Build navigation-points for header- and burgernav
        **/
        $('main section').each((index: any, element: any) => {
            if($(element).attr('id') != null){
                $('.l-topic-nav').append('<li><a href="#' + $(element).attr('id') + '">' + $(element).data('anchor-title') + '</a></li>');
                $('.l-pagetree-panel').append('<li><a href="#' + $(element).attr('id') + '">' + $(element).data('anchor-title') + '</a></li>');
            }
        });

        /* Set active state of menu-items */
        self.setActive();
    }

    /**
     * Function watchEvents():void
     * Watches all events of the landingpagenavigation and handles scrolling
    **/
    public watchEvents():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /* Watch click on menu-items */
        $('.js-landingpage__topic-nav li a, .l-pagetree-panel li a').on('click',function(event: any){
            let anchor = $(event.target).attr('href');
            let anchorScrollTop = $(anchor).offset().top;
            let anchorScrollPosition =  anchorScrollTop - 150;

            if($('.js-l-body').hasClass('js-l-panel-nav--open')) {
                $('.l-panel-nav__backdrop').trigger('click');
            }

            $('html').stop().animate({
                scrollTop: anchorScrollPosition }, 300);
            event.preventDefault();
        });

        /* Watch scrolling of document for setting active state */
        $(window).on('scroll',function () {
            self.setActive();
        });
    }

    /**
     * Function setActive():void
     * Set active state of menu-items
    **/
    public setActive():void {
        $('.js-landingpage__topic-nav li a, .l-pagetree-panel li a').each((index: any, element: any) => {
            let anchor = $(element).attr('href');
            let anchorScrollTop = $(anchor).offset().top;
            let parentItem = $(element).parent('li');
            let scrollCheckPoint = $(window).scrollTop() + 166;

            if (scrollCheckPoint >  (anchorScrollTop + $(anchor).outerHeight(true) ) ) {
                // Do nothing
            } else {
                if (anchorScrollTop < scrollCheckPoint) {
                    parentItem.addClass('active').prev().removeClass('active');
                } else {
                    parentItem.removeClass('active');
                }
            }
        });
    }
}