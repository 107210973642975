/**
 * panel-menu.ts
 * Panel menu with an accordion mode und current opening submenu
**/
var $ = require('jquery');
export class PagetreePanel {

    /**
     * Initalise class variables
    **/
    public accordionMode:any;
    public openCurrent:any;
    public animationDuration:any;
    public overviewpagesPossible:any;
    public menu:any;

    /**
     * Constructor
     * call 'new Panelmenu(true, true, 500, false)'
    **/
    constructor (accordionMode:boolean=true,openCurrent:boolean=true,animationDuration: number=1000,overviewpagesPossible :boolean=false) {
        /**
         * Initalise local constructor variables
        **/
        let self = this;
        self.menu = $('.js-l-pagetree-panel');

        /**
         * Set parameters
        **/
        self.accordionMode = accordionMode;
        self.openCurrent = openCurrent;
        self.animationDuration = animationDuration;
        self.overviewpagesPossible = overviewpagesPossible;

        /* Initialize Menu */
        self.initializeMenu();

        /* Watch events */
        self.watchEvents();
    }

    /**
     * Function initializeMenu():void
     * Initalize menu
     * Set classes for open-states to elements
    **/

    public initializeMenu():void {
        /**
         * Initalise function variables
        **/
        let self = this;

        /**
         * Set additonal info classes to elements
         * add toggle-class to main link only if overviewpages are possible
        **/
        self.menu.find('li').has('ul').addClass('l-pagetree-panel__parent').children('a')
            .append('<span class="js-l-pagetree-panel__parent--toggle l-pagetree-panel__parent--toggle"></span>');

        if (self.overviewpagesPossible == false){
            self.menu.find('li').has('ul').children('a').addClass('js-l-pagetree-panel__parent--toggle').children('.l-pagetree-panel__parent--toggle')
                .removeClass('js-l-pagetree-panel__parent--toggle');
        }

        self.menu.find('li.l-pagetree-panel__item--active').has('ul').addClass('l-pagetree-panel__item-parent--active');

        /**
         * Set open-state to elements
        **/
        if (self.openCurrent == true) {
            $('li.js-l-pagetree-panel__item--active').each(function() {
                if ($(this).children('a').hasClass('active-trail')){
                    $(this).addClass('js-l-pagetree-panel__item-parent--open');
                }
            });
            self.menu.find('li.js-l-pagetree-panel__item-parent--open').has('ul').children('ul').slideDown();
        }
    }

    /**
     * Function watchEvents():void
     * Watches all events of the panelmenu and handles toggeling
    **/
    public watchEvents():void {

        /**
         * Initalise function variables
        **/
        let self = this;
        let toggleButton = $('.js-l-pagetree-panel__parent--toggle');

        /**
         * Watch for clicks on menu-parent item and
         * handle toogling of menus and submenus
        **/
        toggleButton.on('click touchend', function(e:any){
            let currentMenu = $(this).closest('li');
            e.preventDefault();

            /**
             * Handling of click on already opened menu
             **/
            if(currentMenu.hasClass('js-l-pagetree-panel__item-parent--open')){
                currentMenu.removeClass('js-l-pagetree-panel__item-parent--open')
                    .children('ul').stop().slideUp(self.animationDuration)
                    .find('li.js-l-pagetree-panel__item-parent--open')
                    .removeClass('js-l-pagetree-panel__item-parent--open')
                    .children('ul').stop().slideUp(self.animationDuration);
            } else {
                /**
                 * If accordion mode is active close all other panels in menu
                 * unless clicked menu is submenu of already opend
                **/
                if(self.accordionMode) {
                    if(!currentMenu.parents().hasClass('js-l-pagetree-panel__item-parent--open')){
                        self.menu.find('li.js-l-pagetree-panel__item-parent--open')
                        .removeClass('js-l-pagetree-panel__item-parent--open')
                        .find('ul').stop().slideUp(self.animationDuration);
                    }
                }

                /**
                 * Open clicked menu
                **/
                currentMenu.addClass('js-l-pagetree-panel__item-parent--open').children('ul').stop().slideToggle(self.animationDuration);
            }
        });
    }
}